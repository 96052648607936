import request from '@/utils/request'

const registerByEmail = (data) => {
  return request({
    url: '/api/auth/register/email',
    method: 'post',
    data
  })
}

const login = (data) => {
  return request({
    url: 'api/auth/login',
    method: 'post',
    data
  })
}

const registerByMobile = (data) => {
  return request({
    url: '/api/auth/register/mobile',
    method: 'post',
    data
  })
}

const resetPasswordByEmail = (data) => {
  return request({
    url: '/api/auth/password/email',
    method: 'post',
    data
  })
}

const resetPasswordByMobile = (data) => {
  return request({
    url: '/api/auth/password/mobile',
    method: 'post',
    data
  })
}

const getUserInfo = (params) => {
  return request({
    url: '/api/auth',
    method: 'get',
    params
  })
}

const getQrCode = () => {
  return request({
    url: '/api/auth/getqrcode',
    method: 'get'
  })
}

export {
  registerByEmail,
  registerByMobile,
  resetPasswordByEmail,
  resetPasswordByMobile,
  login,
  getQrCode,
  getUserInfo
}
