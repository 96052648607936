<template>
  <el-form ref="form" class="register-mobile" :model="form" :rules="rules">
    <el-form-item prop="nickname">
      <el-input v-model="form.nickname" placeholder="昵称"></el-input>
    </el-form-item>
    <el-form-item prop="mobile">
      <el-input v-model="form.mobile" placeholder="手机号"></el-input>
    </el-form-item>
    <el-form-item prop="captcha">
      <el-input v-model="form.captcha" placeholder="验证码">
        <template #append>
          <el-button @click="Captcha" :disabled="getMobileStatus">{{
            captchaStatus
          }}</el-button>
        </template>
      </el-input>
    </el-form-item>
    <el-form-item prop="password">
      <el-input
        v-model="form.password"
        type="password"
        placeholder="密码"
      ></el-input>
    </el-form-item>
    <el-form-item>
      <el-input
        v-model="form.recommended_id"
        :disabled="!!recommendedId"
        placeholder="邀请人（选填）"
      ></el-input>
    </el-form-item>
    <el-form-item prop="agreement">
      <el-checkbox v-model="form.agreement"
        >我已阅读并同意
        <el-link
          type="primary"
          href="http://www.jukequant.com/agreement.html"
          target="_blank"
          >《软件使用协议》</el-link
        >
      </el-checkbox>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" class="submit-button" @click="submit"
        >注册</el-button
      >
    </el-form-item>
  </el-form>
</template>

<script>
import { registerByMobile } from '@/api/auth'
import { validMobile, validPassword, validCaptcha } from '@/utils/validate'
import { ElMessage, ElMessageBox } from 'element-plus'
import { getMobileCaptcha } from '@/api/verif'

export default {
  props: ['recommendedId'],

  data() {
    return {
      form: {
        mobile: null,
        captcha: null,
        password: null,
        nickname: null,
        agreement: false,
        recommended_id: null
      },
      captchaStatus: '获取验证码',
      loading: false,
      rules: {
        mobile: [
          { required: true, trigger: 'blur', validator: this.validMobile }
        ],
        captcha: [
          { required: true, trigger: 'blur', validator: this.validCaptcha }
        ],
        password: [
          { required: true, trigger: 'blur', validator: this.validPassword }
        ],
        nickname: [{ required: true, message: '昵称不能为空' }],
        agreement: [
          {
            required: true,
            message: '必须同意使用条款',
            validator: this.validAgreement
          }
        ]
      }
    }
  },

  computed: {
    getMobileStatus() {
      return (
        this.captchaStatus != '重新获取' && this.captchaStatus != '获取验证码'
      )
    }
  },

  watch: {
    recommendedId(val) {
      console.log(val)
      this.form.recommended_id = val
    }
  },

  methods: {
    validMobile(rule, value, callback) {
      if (!validMobile(value)) {
        callback(new Error('手机号码格式错误'))
      } else {
        callback()
      }
    },
    validCaptcha(rule, value, callback) {
      if (!validCaptcha(value)) {
        callback(new Error('无效的验证码'))
      } else {
        callback()
      }
    },
    validPassword(rule, value, callback) {
      if (!validPassword(value)) {
        callback(new Error('密码应包含字母和数字，长度8到20位'))
      } else {
        callback()
      }
    },
    validAgreement(rule, value, callback) {
      if (!value) {
        callback(new Error('必须同意使用协议'))
      } else {
        callback()
      }
    },
    captchaLoadErrorCallback() {
        var appid = '195497307';
        // 生成容灾票据或自行做其它处理
        var ticket = 'terror_1001_' + appid + '_' + Math.floor(new Date().getTime() / 1000);
        this.captchaCallback({
          ret: 0,
          randstr: '@'+ Math.random().toString(36).substr(2),
          ticket: ticket,
          errorCode: 1001,
          errorMessage: 'jsload_error'
        });
      },
    captchaCallback(res){
// 第一个参数传入回调结果，结果如下：
          // ret         Int       验证结果，0：验证成功。2：用户主动关闭验证码。
          // ticket      String    验证成功的票据，当且仅当 ret = 0 时 ticket 有值。
          // CaptchaAppId       String    验证码应用ID。
          // bizState    Any       自定义透传参数。
          // randstr     String    本次验证的随机串，后续票据校验时需传递该参数。
          //console.log('callback:', res);
  
          // res（用户主动关闭验证码）= {ret: 2, ticket: null}
          // res（验证成功） = {ret: 0, ticket: "String", randstr: "String"}
          // res（请求验证码发生错误，验证码自动返回terror_前缀的容灾票据） = {ret: 0, ticket: "String", randstr: "String",  errorCode: Number, errorMessage: "String"}
          // 此处代码仅为验证结果的展示示例，真实业务接入，建议基于ticket和errorCode情况做不同的业务处理
          if (res.ret === 0) {
            // 复制结果至剪切板
            var str = '【randstr】->【' + res.randstr + '】      【ticket】->【' + res.ticket + '】';
            var ipt = document.createElement('input');
            ipt.value = str;
            document.body.appendChild(ipt);   
            ipt.select();
            document.execCommand("Copy");
            document.body.removeChild(ipt);
            this.getCaptcha(JSON.stringify(res));
          }
    },
    Captcha(){
      try {
            // 生成一个验证码对象
            // CaptchaAppId：登录验证码控制台，从【验证管理】页面进行查看。如果未创建过验证，请先新建验证。注意：不可使用客户端类型为小程序的CaptchaAppId，会导致数据统计错误。
            //callback：定义的回调函数
            // eslint-disable-next-line no-undef
            var captcha = new TencentCaptcha('195497307', this.captchaCallback, {});
            // 调用方法，显示验证码
            captcha.show(); 
          } catch (error) {
            // 加载异常，调用验证码js加载错误处理函数
            this.captchaLoadErrorCallback();
            }
    },
    getCaptcha(res) {
      if (!this.form.mobile) {
        ElMessage.error('请输入手机号码')
      } else {
        let time = 60
        this.timer = setInterval(() => {
          this.captchaStatus = time + 's'
          time--
          if (time <= 0) {
            clearInterval(this.timer)
            this.timer = null
            this.captchaStatus = '重新获取'
          }
        }, 1000)
        this.loading = true
        getMobileCaptcha({ mobile: this.form.mobile,CaptchaResulr:res})
          .then((resp) => {
            if (resp.data) {
              ElMessage({
                type: 'success',
                message: '验证码已通过短信下发到你的手机，请注意查收'
              })
              this.loading = false
            }
          })
          .catch(() => {
            this.loading = false
          })
      }
    },
    submit() {
      clearInterval(this.timer)
      this.timer = null
      this.$refs.form.validate((valid) => {
        if (valid) {
          registerByMobile(this.form).then(() => {
            this.$router.push('/login')
            /*
            ElMessageBox.confirm(
              '恭喜你完成了注册，你可在未来24小时内免费使用本软件，现在是否立即下载量化交易客户端进行交易？',
              '提示',
              {
                type: 'success',
                confirmButtonText: '确定',
                cancelButtonText: '取消'
              }
            ).then(() => {
              this.$router.push('/login')
            })
            */
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.register-mobile {
  text-align: left;
  .submit-button {
    width: 100%;
  }
}
</style>
