<template>
  <el-form ref="form" class="register-email" :model="form" :rules="rules">
    <el-form-item prop="nickname">
      <el-input v-model="form.nickname" placeholder="昵称"></el-input>
    </el-form-item>
    <el-form-item prop="email">
      <el-input v-model="form.email" placeholder="邮箱"></el-input>
    </el-form-item>
    <el-form-item prop="captcha">
      <el-input v-model="form.captcha" placeholder="验证码">
        <template #append>
          <el-button :loading="loading" @click="getCaptcha">
            {{ captchaStatus }}
          </el-button>
        </template>
      </el-input>
    </el-form-item>
    <el-form-item prop="password">
      <el-input
        v-model="form.password"
        type="password"
        placeholder="密码"
      ></el-input>
    </el-form-item>
    <el-form-item>
      <el-input v-model="form.superior" placeholder="邀请人（选填）"></el-input>
    </el-form-item>
    <el-form-item prop="agreement">
      <el-checkbox v-model="form.agreement"
        >我已阅读并同意
        <el-link
          type="primary"
          href="http://www.jukequant.com/agreement.html"
          target="_blank"
          >《软件使用协议》</el-link
        >
      </el-checkbox>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" class="submit-button" @click="submit"
        >注册</el-button
      >
    </el-form-item>
  </el-form>
</template>

<script>
import { registerByEmail } from '@/api/auth'
import { getEmailCaptcha } from '@/api/verif'
import { validEmail, validPassword, validCaptcha } from '@/utils/validate'
import { ElMessage, ElMessageBox } from 'element-plus'

export default {
  data() {
    return {
      form: {
        email: null,
        captcha: null,
        password: null,
        nickname: null,
        superior: null,
        agreement: false
      },
      captchaStatus: '获取验证码',
      loading: false,
      rules: {
        email: [
          { required: true, trigger: 'blur', validator: this.validEmail }
        ],
        captcha: [
          { required: true, trigger: 'blur', validator: this.validCaptcha }
        ],
        password: [
          { required: true, trigger: 'blur', validator: this.validPassword }
        ],
        nickname: [{ required: true, message: '昵称不能为空' }],
        agreement: [
          {
            required: true,
            message: '必须同意使用条款',
            validator: this.validAgreement
          }
        ]
      }
    }
  },
  methods: {
    validEmail(rule, value, callback) {
      if (!validEmail(value)) {
        callback(new Error('邮箱格式错误'))
      } else {
        callback()
      }
    },
    validCaptcha(rule, value, callback) {
      if (!validCaptcha(value)) {
        callback(new Error('无效的验证码'))
      } else {
        callback()
      }
    },
    validPassword(rule, value, callback) {
      if (!validPassword(value)) {
        callback(new Error('密码应包含字母和数字，长度8到20位'))
      } else {
        callback()
      }
    },
    validAgreement(rule, value, callback) {
      if (!value) {
        callback(new Error('必须同意使用协议'))
      } else {
        callback()
      }
    },
    getCaptcha() {
      if (!this.form.email) {
        ElMessage.error('请输入电子邮箱账号')
      } else {
        let time = 60
        let timer = setInterval(() => {
          this.captchaStatus = time + 's'
          time--
          if (time <= 0) {
            timer = null
            this.captchaStatus = '重新获取'
          }
        }, 1000)
        this.loading = true
        getEmailCaptcha({ email: this.form.email })
          .then((resp) => {
            if (resp.data) {
              ElMessage({
                type: 'success',
                message: '验证码已发送到您的邮箱，请注意查收'
              })
              this.loading = false
            }
          })
          .catch(() => {
            this.loading = false
          })
      }
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          registerByEmail(this.form).then(() => {
            ElMessageBox.confirm(
              '恭喜你完成了注册，你可在未来24小时内免费使用本软件，现在是否立即下载量化交易客户端进行交易？',
              '提示',
              {
                type: 'success',
                confirmButtonText: '确定',
                cancelButtonText: '取消'
              }
            ).then(() => {
              this.$router.push('/login')
            })
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.register-email {
  text-align: left;
  .submit-button {
    width: 100%;
  }
}
</style>
