<template>
  <el-row class="register" :class="{ isMobile }">
    <el-col :span="14"> </el-col>
    <el-col :span="8" :xs="24">
      <el-container>
        <el-header>
          <h3>欢迎注册聚客量化</h3>
        </el-header>
        <el-main>
          <!-- <el-radio-group v-model="isEmail">
            <el-radio-button :label="true">邮箱注册</el-radio-button>
            <el-radio-button :label="false">手机注册</el-radio-button>
          </el-radio-group> -->
          <div class="form">
            <register-by-email
              v-if="isEmail"
              :recommendedId="recommendedId"
            ></register-by-email>
            <register-by-mobile
              v-else
              :recommendedId="recommendedId"
            ></register-by-mobile>
          </div>
        </el-main>
      </el-container>
    </el-col>
  </el-row>
</template>

<script>
import RegisterByEmail from './components/RegisterByEmail'
import RegisterByMobile from './components/RegisterByMobile'

export default {
  components: {
    RegisterByEmail,
    RegisterByMobile
  },

  data() {
    return {
      isEmail: false,
      isMobile: true,
      recommendedId: null
    }
  },

  mounted() {
    this.recommendedId = this.$route.query.recommended_id
    this.isMobile = 'ontouchstart' in document.documentElement
    if (this.$store.state.token) {
      this.$router.replace('/')
    }
  }
}
</script>

<style lang="scss" scoped>
.register {
  height: 100vh;
  text-align: left;
  background: url('/static/img/background.png') no-repeat;
  background-size: 100% 100%;

  form {
    margin-top: 30px;
  }
}

.register.isMobile {
  background: none;
}
</style>
