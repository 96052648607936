import request from '@/utils/request'

const getEmailCaptcha = (data) => {
  return request({
    url: '/api/verify/email',
    method: 'post',
    data
  })
}

const getMobileCaptcha = (data) => {
  return request({
    url: '/api/verify/mobile',
    method: 'post',
    data
  })
}

export { getEmailCaptcha, getMobileCaptcha }
